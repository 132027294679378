import * as React from "react";
import {
  PlasmicProcessCardUmgebungsgestaltung,
  DefaultProcessCardUmgebungsgestaltungProps
} from "./plasmic/imbas_23_fpre/PlasmicProcessCardUmgebungsgestaltung";

export interface ProcessCardUmgebungsgestaltungProps
  extends DefaultProcessCardUmgebungsgestaltungProps {}

function ProcessCardUmgebungsgestaltung(
  props: ProcessCardUmgebungsgestaltungProps
) {
  return <PlasmicProcessCardUmgebungsgestaltung {...props} />;
}

export default ProcessCardUmgebungsgestaltung;

import { PlasmicCanvasHost } from "@plasmicapp/host";
import React, { useEffect, useState } from "react";
import { registerFormWrapperComponent } from "../components/custom/FormWrapper";
import { registerWiredFormField } from "../components/custom/WiredFormField";
import { registerDynamicTextComponent } from "../components/custom/DynamicText";
import { registerSliderComponent } from "../components/custom/SliderBase";
import { registerBoxPlotChartComponent } from "../components/custom/BoxPlotChart";

function PlasmicHost() {
  const [canvasHost, setCanvasHost] = useState<React.ReactNode>(null);

  useEffect(() => {
    registerFormWrapperComponent();
    registerWiredFormField();
    registerDynamicTextComponent();
    registerSliderComponent();
    registerBoxPlotChartComponent();

    setCanvasHost(<PlasmicCanvasHost/>);
  }, []);

  return React.isValidElement(canvasHost) ? canvasHost : <React.Fragment/>;
}

export default PlasmicHost;
